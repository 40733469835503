import React from 'react';
import {Heading, Paragraph} from "../UI/Typography";
import {FormattedMessage} from "react-intl";
import Link from "../Link";
import {Button} from "../UI/Button";
import Fade from "react-reveal/Fade.js";

const services = [
    {
        title: 'services.data_analysis_consulting',
        list: ['services.insights', 'services.business_research'],
        color: '#8227EE',
        colorName: 'violet',
        name: 'analysis',
        description: 'services.data_analysis_consulting_desc',
        link: '/service-detail/data-analysis-and-consulting/'
    },
    {
        title: 'services.design_development',
        list: ['services.digital_product', 'services.website'],
        color: '#000',
        colorName: 'black',
        name: 'designdev',
        description: 'services.design_development_desc',
        link: '/service-detail/design-and-development/'
    },
    {
        title: 'services.creative_communication',
        list: ['services.social_management', 'services.video_production'],
        color: '#10CFC4',
        colorName: 'green',
        name: 'communication',
        description: 'services.creative_communication_desc',
        link: '/service-detail/creative-communication/'
    },
    {
        title: 'services.marketing_strategy',
        list: ['services.inbound_marketing', 'services.performance_advertising'],
        color: '#499FF9',
        colorName: 'blue',
        name: 'marketing',
        description: 'services.marketing_strategy_desc',
        link: '/service-detail/marketing-and-strategy/'
    }
];

const OurServices = ({intl}) => {
    return <div className="OurServices container">
        <div className="row">
            <div className="col-12">
                <Heading level={'3'}>
                    <FormattedMessage id="services.services"/>
                </Heading>
            </div>
        </div>
        {services.map((service, index) => <SingleService service={service} key={index} intl={intl}/>)}
    </div>
};

export default OurServices;

const SingleService = ({service, intl}) => {
    const {title, list, color, colorName, description, link, name} = service;

    return <Fade bottom delay={500}>
        <div className="row single-service" id={name}>
            <div className="col-md-6 col-12">
                <Heading level={'4'}><FormattedMessage id={title}/></Heading>
                <ul style={{color}}>
                    {list.map((item, index) => <li key={index}><FormattedMessage id={item}/></li>)}
                </ul>
            </div>
            <div className="col-md-6 col-12">
                <Paragraph>
                    <FormattedMessage id={description}/>
                </Paragraph>
                <Link to={link} className="link-no-decoration">
                    <Button arrow label={intl.formatMessage({id: 'services.see_more_cta'})} nopadding={true} customArrowClass={`arrow-${colorName}`}/>
                </Link>
            </div>
        </div>
    </Fade>
};
