import React from "react";
import Hero from '../components/Hero';
import withLayout from "../layout";
import {Heading} from "../components/UI/Typography";
import Dash from "../components/UI/Dash";
import {FormattedMessage, injectIntl} from "react-intl";
//Partners SVG
import FuoriCitta from '../images/services/fuocitta.svg';
import Idib from '../images/services/idib.svg';
import Luther from '../images/services/luther.svg';
import SeeWeb from '../images/services/seeweb.svg';
//OurServices
import OurServices from '../components/OurServices';
import SEO from "../components/SEO";
import FullWidthMessage from "../components/FullWidthMessage";

const ServicesPage = ({intl}) => {
    const keywords = intl.formatMessage({id: 'services.meta_keywords'}).split(',') || [];

    return <div>
        <SEO title={intl.formatMessage({id: 'services.meta_title'})}
             description={intl.formatMessage({id: 'services.meta_description'})} keywords={keywords}
             lang={intl.locale}/>
        <Hero subtitle={'services.subtitle'} title={'services.title'} thinChild={'3'}
              paragraph={'services.welcome_text'}/>
        <OurServices intl={intl}/>
        <FullWidthMessage title={"services.banner_title"} text1={"services.banner_paragraph"}
                          ctaText={"services.banner_cta"}
                          ctaLink={"/contact-us/"} blur={true} intl={intl}/>
        {/* brand area */}
        <div className="section-inner-padding">
            <div className="container">
                <div className="row my-3">
                    <div className="col-12">
                        <label className="special-label">
                            <FormattedMessage id={"services.our_partners"}/>
                        </label>
                        <Heading level={"2"} margin={'24px 0px'} thinChild={1}>
                            <FormattedMessage id={"services.always"}/>
                            <FormattedMessage id={"services.our_side"}/>
                        </Heading>
                        <Dash margin={'0px 0px 64px 0px'}/>
                    </div>
                </div>

                <div className="row justify-content-center align-items-center">
                    <div className="col-md col-6 d-flex justify-content-center align-items-center mb-5 mb-md-0">
                        <img src={Idib} alt={"Idib logo"} className='services-logo img-fluid'/>
                    </div>
                    <div className="col-md col-6 d-flex justify-content-center align-items-center mb-5 mb-md-0">
                        <img src={Luther} alt={"Luther logo"} className='services-logo img-fluid'/>
                    </div>
                    <div className="col-md col-6 d-flex justify-content-center align-items-center">
                        <img src={FuoriCitta} alt={"Fuoricitta logo"} className='services-logo img-fluid'/>
                    </div>
                    <div className="col-md col-6 d-flex justify-content-center align-items-center">
                        <img src={SeeWeb} alt={"Seeweb logo"} className='services-logo img-fluid'/>
                    </div>
                </div>
            </div>
        </div>
    </div>
};
const customProps = {
    localeKey: "agency", // same as file name in src/i18n/translations/your-lang/index.js
};

export default withLayout(customProps)(injectIntl(ServicesPage));
